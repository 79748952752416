import React, { useState } from 'react'
import { Link } from 'gatsby'

import img1 from '../../assets/images/gengigel/gengigel_img1.jpg'
import img2 from '../../assets/images/gengigel/gengigel_img2.png'
import img3 from '../../assets/images/gengigel/gengigel_img3.png'

import imgTeamContact from '../../assets/images/img-team-2.png'

import SEO from '../../components/SEO'
import Header from '../../components/Header'
import TagsMarquee from '../../components/TagsMarquee'
import MintButtons from '../../components/MintButtons'
import ShareButtons from '../../components/ShareButtons'

import { colors } from '../../constants/theme'

import {
  ProjectDetailsLayout,
  SectionCover,
  SectionIntro,
  SectionRichText,
  SectionFullImage,
  SectionFooter,
  SectionNavigator,
  StickyButtons,
  Square,
} from '../../styles/ProjectDetailsLayout'
import { Content } from '../../styles/PageLayout'
import { HeadlineM, TitleM, ParagraphL, ParagraphM, ParagraphS } from '../../styles/Typography'

/**
 * A project details template, to be used in gatsby-node.
 * Includes sections that can be conditionally rendered.
 * @returns {StatelessComponent}
 */
const ProjectDetails = () => {
  const [selected, setSelected] = useState(0)

  const tags = [
    'UX dizajn',
    'UI design',
    'UX dizajn',
    'UI design',
    'UX dizajn',
    'UI design',
    'UX dizajn',
    'UI design',
    'UX dizajn',
    'UI design',
    'UX dizajn',
    'UI design',
    'UX dizajn',
    'UI design',
    'UX dizajn',
    'UI design',
  ]

  return (
    <>
      <SEO
        title="Mint and Square | Gengigel"
        description="Za podjetje Medis smo oblikovali spletno stran in uporabniško izkušnjo na spletni strani za izdelek Gengigel."
        image={img1}
      />
      <Header
        logoBackground={colors.blue}
        logoBorder={colors.blue}
        linkText={colors.blue}
        menuLines={colors.blue}
      />

      <ProjectDetailsLayout>
        {/* Cover Section - Aways visible */}
        <SectionCover>
          <div className="cover-image">
            <img src={img1} alt="Gengigel" />
          </div>
          <Square background={colors.blue} className="title">
            <ParagraphM color={colors.white}>Medis</ParagraphM>
            <HeadlineM color={colors.yellow}>Gengigel</HeadlineM>
          </Square>
          <div className="tags">
            <TagsMarquee tags={tags} />
          </div>
        </SectionCover>

        {/* Intro Section - Aways visible */}
        <SectionIntro background={colors.blue}>
          <Content>
            <TitleM color={colors.white}>Izziv</TitleM>
            <ParagraphL color={colors.white} weight="300">
              Predstaviti izdelek za lajšanje bolečin in krvaveče dlesni v bolj sproščeni podobi.
            </ParagraphL>
          </Content>
          <Content>
            <TitleM color={colors.white}>Opis</TitleM>
            <ParagraphL color={colors.white} weight="300">
              Za podjetje Medis smo oblikovali spletno stran in uporabniško izkušnjo na spletni
              strani za izdelek Gengigel.
            </ParagraphL>
          </Content>
        </SectionIntro>

        <div className="section-wrapper">
          {/* Sticky Share and Mint buttons - Aways visible */}
          <StickyButtons>
            <MintButtons isColumn selected={selected} setSelected={setSelected} />
            <ShareButtons isColumn />
          </StickyButtons>

          {/* FullImage Section - Conditionally visible */}
          <SectionFullImage>
            <img src={img2} alt="Gengigel" />
          </SectionFullImage>

          {/* FullImage Section - Conditionally visible */}
          <SectionFullImage>
            <img src={img3} alt="Gengigel" />
          </SectionFullImage>

          {/* RichText Section - Conditionally visible */}
          <SectionRichText>
            <Content>
              {/* Example Paragraph */}
              <p>
                Spletna stran je na voljo na{' '}
                <a href="https://www.gengigel.si" target="_blank">
                  www.gengigel.si
                </a>
                .
              </p>
            </Content>
          </SectionRichText>
        </div>

        {/* Footer Section - Aways visible */}
        <SectionFooter>
          <ParagraphL>Kako se ti je zdel ta projekt?</ParagraphL>
          <div className="mint-buttons">
            <MintButtons selected={selected} setSelected={setSelected} />
          </div>
          <div className="share-buttons">
            <ShareButtons />
          </div>
          <div className="contact">
            <img src={imgTeamContact} alt="Contact Team" />
            <ParagraphS weight="300">
              Živjo, sem Romina. Ustanoviteljica Mint & Square agencije in kreativna direktorica.
              Želiš več informacij o tej objavi, ali pa stopiti v kontakt? Lahko mi napišeš kar na{' '}
              {''}
              <a href="https://www.linkedin.com/in/romina-gerbec/" target="_blank">
                LinkedIn
              </a>{' '}
              👌
            </ParagraphS>
          </div>
        </SectionFooter>

        <SectionNavigator>
          <Link to="/project/mlada-pobuda">
            <ParagraphL>← Prejšnji projekt </ParagraphL>
          </Link>
          <Link to="/project/slovenia-green">
            <ParagraphL>Naslednji projekt →</ParagraphL>
          </Link>
        </SectionNavigator>
      </ProjectDetailsLayout>
    </>
  )
}
export default ProjectDetails
